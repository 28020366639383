.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.calculator {
  background-color: #3a3f47;
  padding: 10px;
  border-radius: 10px;
  width: 412px;
  margin: 0 auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.input-group {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

input {
  margin: 0.5em 0;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50px;
  -moz-appearance: textfield;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-arrows {
  -moz-appearance: textfield;
}

button {
  margin-top: 1em;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: #282c34;
  transition: background-color 0.3s;
  width: 100%;
}

button:hover {
  background-color: #21a1f1;
}

.output-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.output-box {
  background-color: #3a3f47;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

h2 {
  margin-top: 0.5em;
}